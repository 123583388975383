.progress-bar {
  width: 100%;
  height: 15px;
  background: #f0f0f0;
  border-radius: 10px;
  overflow: hidden;
}

.progress-bar-inner {
  height: 100%;
  background: linear-gradient(to right, #4caf50, #4caf50);
  animation: progress-animation 3s linear infinite;
  width: 100%; /* Ensure width is always 100% */
}

@keyframes progress-animation {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}
