/* In your tailwind.css file or equivalent */
.text.big {
    @apply text-3xl; /* Big size */
    transition: font-size 0.5s ease; 
  }
  
  .text.small {
    @apply text-xl; /* Small size */
    
  }
  
  .container {
    @apply flex justify-center items-center;
  }
  
  .stackedDiv {
    @apply w-full;
  }
  
  .otherClass {
    @apply w-[40%] /* 30% width */
  }
  