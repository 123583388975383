.slider-item {
   /* Adjust this accordingly */
    margin-right: 10px;
    margin-left: 10px;
}

.slick-track {
    padding-bottom: 20px;
    padding-top: 25px;
}

.slick-slide div {
    width: 95%;
    border-radius: 16px;
    
}
.slick-slides {
    width: 913px !important;  /* Ensure each slide is 913px wide */
    height: 471px !important; /* Ensure each slide is 471px tall */
  }
/* To prevent margin-right for the last item */
.slider-item:last-child {
    margin-right: 0;
}

.slick-slide {
    transition: all 0.3s;
}

.active-slide {
    background-color: #00BF63;
    color: #fff;
    transform: scale(1.05);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    z-index: 999;
}


.green-overlay {
    position: absolute;
    top: 1278px;
    left: 52%;
    transform: translate(-50%, -50%);
    width: 30%; /* Adjust width as needed */
    height: 225px; /* Adjust height as needed */
    background-color: #00BF63;
    color: #fff;
    border-radius: 16px; /* Adjust border-radius as needed */
    background-clip: padding-box ;  /* Ensure this is above the slider */
}
.passing-card {
    opacity: 0.5; /* Adjust opacity as needed */
    transition: opacity 0.3s ease-in-out;
}

.active-overlay {
    position: absolute;
    left: 52%;; /* Adjust based on the card's width */
    top: 1278px;
    transform: translate(-50%, -50%);
    width: 387px; /* Adjust width as needed */
    height: 100%; /* Cover the full height of the slider */
    background-color: #00BF63;
    border-radius: 8px; /* Adjust border-radius as needed */
    z-index: -1; /* Ensure this is behind the slider */
    pointer-events: none; /* Allow clicks to pass through */
}
