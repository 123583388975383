@tailwind base;
@tailwind components;
@tailwind utilities;

[type="text"],
input:where(:not([type])),
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
  appearance: none;
  background-color: transparent;
  border-color: unset;
  border-width: 0;
  border-radius: unset;
  padding: unset;
  font-size: unset;
  line-height: unset;
}
.radio-input:checked + .circle-icon {
  background-color: white;
}
/* Override default focus colors for tailwindcss-forms https://github.com/tailwindlabs/tailwindcss-forms */
[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="search"]:focus,
[type="checkbox"]:focus,
[type="radio"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  --tw-ring-color: "#37c971";
  border-color: "#37c971";
}
.slider::-webkit-slider-thumb {
  appearance: none;
  height: 20px;
  width: 20px;
  background-color: black;
  border-radius: 50%;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  height: 20px;
  width: 20px;
  background-color: black;
  border-radius: 50%;
  cursor: pointer;
}
